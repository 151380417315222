import { QueryKey } from '@tanstack/react-query';

export const getGroupsKey = ({ locale }: { locale: string }): QueryKey => [
  'groups',
  locale,
];

export const getGroupsWithStockKey = ({
  locale,
  reference = null,
  bp_reference = null,
  min_price = null,
  max_price = null,
  car_identifier = null,
}: {
  locale: string;
  reference?: string | null;
  bp_reference?: string | null;
  min_price?: string | null;
  max_price?: string | null;
  car_identifier?: string | null;
}): QueryKey => [
  'groups',
  locale,
  reference,
  bp_reference,
  min_price,
  max_price,
  car_identifier,
];

export const getBrandsKey = ({ locale }: { locale: string }): QueryKey => [
  'brands',
  locale,
];

export const getLastVehiclesKey = ({
  locale,
  part,
  vehicle,
  category,
}: {
  locale: string;
  part?: string;
  vehicle?: string;
  category?: string;
}): QueryKey => {
  const values = new Map();

  values.set('locale', locale);

  if (part) {
    values.set('partIdentifier', part);
  }

  if (vehicle) {
    values.set('carIdentifier', vehicle);
  }
  if (category) {
    values.set('category_slug', category);
  }

  return ['lastVehicles', locale, part || null, vehicle];
};

export const getLastEntriesKey = ({
  locale,
  part,
  vehicle,
  detailed_prices,
}: //category,
{
  locale: string;
  part?: string;
  vehicle?: string;
  detailed_prices?: boolean;
  //category?: string;
}): QueryKey => {
  /*const values = new Map();

  values.set('locale', locale);

  if (part) {
    values.set('partIdentifier', part);
  }

  if (vehicle) {
    values.set('carIdentifier', vehicle);
  }

  /*if (category) {
    values.set('category_slug', category);
  }*/

  return ['lasEntries', locale, part || null, vehicle, detailed_prices];
};

export const getStockKey = ({
  locale,
  no_round = false,
  stocks,
  csrftoken,
}: {
  locale: string;
  no_round?: boolean;
  stocks: Array<{
    part?: string;
    vehicle?: string;
    category?: string;
  }>;
  csrftoken: string;
}): QueryKey => {
  const stocksObject: {
    part?: string;
    vehicle?: string;
    category?: string;
  }[] = [];

  stocks?.forEach((stock) => {
    const values = new Map();
    if (stock.vehicle) {
      values.set('car_identifier', stock.vehicle);
    }

    if (stock.part) {
      values.set('part_identifier', stock.part);
    }

    if (stock.category) {
      values.set('category_identifier', stock.category);
    }
    stocksObject.push(Object.fromEntries(values));
  });

  return [
    'fetchStocks',
    locale,
    {
      no_round: no_round,
      stocks: stocksObject,
    },
    csrftoken,
  ];
};

export const getCompanyDetailsKey = ({
  locale,
  users,
}: {
  locale: string;
  users?: boolean;
}): QueryKey => {
  const values = new Map();
  if (users) {
    values.set('users', users);
  }

  return [
    'user-dependent',
    'company-details',
    locale,
    Object.fromEntries(values),
  ];
};

export const getCountriesKey = ({ locale }: { locale: string }): QueryKey => [
  'coutries',
  locale,
];

export const getCountriesShippingKey = ({
  locale,
}: {
  locale: string;
}): QueryKey => ['coutries-shipping', locale];

export const getCountriesPhonesKey = ({
  locale,
}: {
  locale: string;
}): QueryKey => ['coutries-phones', locale];

export const getValidateTokenKey = ({
  locale,
  token,
}: {
  locale: string;
  token: string;
}): QueryKey => ['password-reset-confirm', locale, token];

export const getCheckoutDetailsKey = ({
  locale,
}: {
  locale: string;
}): QueryKey => ['user-dependent', 'checkout-details', locale];

export const getSaveVinKey = ({
  locale,
  orderId,
  vin,
  csrftoken,
}: {
  locale: string;
  orderId: number;
  vin: string;
  csrftoken: string;
}): QueryKey => [
  'user-dependent',
  'checkout-details',
  locale,
  orderId,
  vin,
  csrftoken,
];

export const getCartKeys = ({
  locale,
  isAdvancedView,
  detailedPrice,
}: {
  locale: string;
  isAdvancedView?: boolean;
  detailedPrice?: boolean;
}): QueryKey => [
  'user-dependent',
  'cart',
  locale,
  isAdvancedView,
  detailedPrice,
];

export const getLoggedUserKeys = ({ locale }: { locale: string }): QueryKey => [
  'user-dependent',
  'logged-user',
  locale,
];

export const getFullRegistrationKeys = (
  fields: TFullRegistration
): QueryKey => ['full-registration', ...Object.keys(fields)];

export const getSaveUserCarKeys = ({
  locale,
  csrftoken,
}: {
  locale: string;
  csrftoken: string;
}): QueryKey => ['user-dependent', 'saved-cars', locale, csrftoken];

export const getTranslateSlugsKeys = ({
  lang_origin,
  slugs,
}: {
  lang_origin: string;
  csrftoken: string;
  slugs: Array<{
    slug_type: 'category' | 'part' | 'product';
    slug_origin: string;
  }>;
}): QueryKey => ['translate-slugs', lang_origin, slugs];
